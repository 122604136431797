import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import tw from 'tailwind.macro';

import BlockContent from '@components/BlockContent';
import { hasImage } from '@utils/helpers';
import { Container, Button } from '@elements';

const HeroBusinessCard = ({ data, rawData }) => {
  const imageExists = hasImage(data.image);

  return (
    <div className="relative color-mode-dark color-mode-background-200 overflow-hidden container-cousin">
      <Container className="">
        <div className="flex flex-col space-x-10 items-center justify-start lg:flex-row lg:justify-between">
          <Text className="text-center lg:text-left">
            <BlockContent blocks={rawData.text || []} />

            <div className="mt-6">
              {data.buttons &&
                data.buttons.map((button) => (
                  <Button
                    key={button._key}
                    buttonObject={button}
                    className="w-3/4 mb-3 sm:mr-3 sm:mb-0 shadow-none sm:w-auto"
                  />
                ))}
            </div>
          </Text>
          <div className="relative flex-none relative w-75 h-75 mt-12 md:w-100 md:h-100 lg:mt-0">
            <div className="relative mx-auto w-full h-full rounded-full shadow-lg">
              <div className="relative block w-full h-full rounded-full overflow-hidden relative">
                {imageExists && (
                  <Img
                    className="w-full h-full object-cover"
                    fluid={data.image.asset.fluid}
                    alt={data.image.alt}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroBusinessCard;

const Text = styled.div`
  p {
    ${tw`mt-3 text-base sm:mt-5 sm:text-xl lg:text-lg xl:text-xl`};
  }

  h1,
  h2,
  h3 {
    ${tw`mt-1 tracking-tight leading-10 font-extrabold sm:leading-none`};
  }
`;

export const HeroBusinessCardFragment = graphql`
  fragment HeroBusinessCardFragment on SanityHeroBusinessCard {
    _key
    _type
    buttons {
      ...ButtonFragment
    }
    image {
      asset {
        fluid(maxWidth: 1600) {
          ...GatsbySanityImageFluid
        }
      }
      alt
      caption
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
