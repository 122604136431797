import React from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
// import clientConfig from '../../client-config'
import { YoutubePlayer, Link } from '@elements';

const addContainsMarkClasses = (children) => {
  let returnStylesArray = [];
  if (children) {
    children.forEach(function (child) {
      child.marks.forEach(function (mark) {
        if (mark === 'center') {
          returnStylesArray.push('blockContainsCenterText');
        }
        if (mark === 'textPrimary') {
          returnStylesArray.push('blockContainsPrimaryText');
        }
      });
    });
  }

  returnStylesArray = Array.from(new Set(returnStylesArray));

  return returnStylesArray.join(' ');
};
// const addContainsMarkClasses = props => null;

const serializers = {
  // types: {
  //   block (props) {
  //     switch (props.node.style) {
  //       case 'h1':
  //         return <h1>{props.children}</h1>
  //        // ...
  //       default:
  //         return <p>{props.children}</p>
  //     }
  //   },
  // },
  // marks: {
  //   inlineicon (props) {
  //     switch (props.mark._type){
  //       case 'inlineicon':
  //         if(props.mark.asset) { return <InlineIcon src={props.mark.asset.url || ''} alt={props.children[0]}/> } else { return null }
  //     }
  //   }
  // }
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return (
            <p className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </p>
          );
        case 'h2':
          return (
            <p className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </p>
          );
        case 'h3':
          return (
            <p className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </p>
          );
        case 'h4':
          return (
            <p className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </p>
          );
        case 'h5':
          return (
            <p className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </p>
          );
        case 'h6':
          return (
            <p className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </p>
          );
        case 'lg':
          return (
            <p
              className={`text-xl leading-7 sm:text-2xl sm:leading-8 ${addContainsMarkClasses(
                props.node.children[0].marks
              )}`}
            >
              {props.children}
            </p>
          );
        case 'xl':
          return (
            <p
              className={`text-2xl leading-8 sm:text-3xl sm:leading-9 ${addContainsMarkClasses(
                props.node.children[0].marks
              )}`}
            >
              {props.children}
            </p>
          );
        default:
          return (
            <p className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </p>
          );
      }
    },
    youtube: ({ node }) => {
      const opts = {
        height: '100%',
        width: '100%',
        color: 'white',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      };
      const { url } = node;
      return <YoutubePlayer opts={opts} url={url} />;
    },
  },
  marks: {
    center: (props) => <p className="text-center">{props.children}</p>,
    textPrimary: (props) => (
      <span className="text-primary">{props.children}</span>
    ),
    link: ({ mark, children }) => (
      <Link link={mark.link} text={children[0]}>
        {children}
      </Link>
    ),
  },
  // marks: {
  //   inlineicon (props) {
  //     switch (props.mark._type){
  //       case 'inlineicon':
  //         if(props.mark.asset) { return <InlineIcon src={props.mark.asset.url || ''} alt={props.children[0]}/> } else { return null }
  //     }
  //   }
  // }
};

const BlockContentSimple = ({ blocks, className }) => (
  <BaseBlockContentWrap className={className}>
    <BaseBlockContent blocks={blocks} serializers={serializers} />
  </BaseBlockContentWrap>
);

const BaseBlockContentWrap = styled.div`
  & *:last-child {
    margin-bottom: 0 !important;
  }
`;

export default BlockContentSimple;
