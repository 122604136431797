export const fields = [
  {
    question: 'Personal Info',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'First Name',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Last Name',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Country',
    type: 'country',
    default: null,
    options: null,
  },
  {
    question: 'State/Province',
    type: 'state',
    default: null,
    options: null,
  },
  {
    question: 'Full Address',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Home Phone',
    type: 'tel',
    default: null,
    options: null,
  },
  {
    question: 'Work Phone',
    type: 'tel',
    default: null,
    options: null,
  },
  {
    question: 'Email',
    type: 'email',
    default: null,
    options: null,
  },
  {
    question: 'Physical History',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'General Health',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question: "Are you now under a doctor's care?",
    type: 'radio',
    default: null,
    options: ['Yes', 'No'],
    fullWidth: true,
  },
  {
    question: 'If yes, name of doctor',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Reason for Doctors Care',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Are you taking any medication?',
    type: 'radio',
    default: null,
    options: ['Yes', 'No'],
    fullWidth: true,
  },
  {
    question: 'If yes, What Kind',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Reason for Medication',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Last Medical Examination',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Have you ever been hospitalized for a physical illness?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
    fullWidth: true,
  },
  {
    question: 'Describe',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Any recent major illnesses or surgeries?',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Any recurrent or chronic conditions?',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Do you smoke?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'Do you take drugs?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'If yes, What Kind',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Do you take drink?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'How Much',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Any Previous Therapy/Counseling?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'If yes, describe, when, where, how long, what for',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'What do you hope to achieve with therapy?',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question: 'Work History',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'Occupation',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'How Long?',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'If presently unemployed, describe the situation?',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question: 'Family Systems Information',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'Where born',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'How long there',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Ethnic ID',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Father Alive?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
    fullWidth: true,
  },
  {
    question: 'Where is he residing',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Relationship',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Mother Alive?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
    fullWidth: true,
  },
  {
    question: 'Where is she residing',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Relationship',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Marital Status',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Number of marriages',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: "Spouse's name",
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Living with a partner',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'How Long',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Partners Name',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Children',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'Child 1 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Child 1 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Child 2 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Child 2 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Child 3 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Child 3 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Child 4 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Child 4 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Child 5 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Child 5 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Child 6 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Child 6 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Siblings',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'Sibling 1 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Sibling 1 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Sibling 2 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Sibling 2 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Sibling 3 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Sibling 3 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Sibling 4 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Sibling 4 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Sibling 5 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Sibling 5 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Sibling 6 - sex',
    type: 'radio',
    options: ['Male', 'Female'],
    default: null,
  },
  {
    question: 'Sibling 6 - age',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Family Alcoholism or Domestic Violence?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'Sexual Addictions or Abuse',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'Parents divorced?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
    fullWidth: true,
  },
  {
    question: 'If yes, what year?',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Your age at the time?',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'If deceased, what year?',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Cause of death',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Any step-parents?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
    fullWidth: true,
  },
  {
    question: 'If yes, describe when and your relationship with them',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question:
      'If reared by someone other than your birth parents, describe the situation in some detail Tell anything else in the space below that you think would be helpful for me, as your therapist, to know',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question: 'Spiritual History',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'Religious upbringing',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Present Affiliation',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Is this an important part of your life?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
    fullWidth: true,
  },
  {
    question: 'Why not ?',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question: 'Emotional Status',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'Are you currently experiencing strong emotions?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'If yes, describe',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Do you make decisions based on your emotions?',
    type: 'radio',
    default: null,
    options: ['Yes', 'No'],
  },
  {
    question: 'How well does that work for you',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question:
      'Did you have what you would consider to be childhood or other traumas?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'If yes, describe',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Have you been treated for emotional disturbances?',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'If yes, when',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Have you had any thoughts of suicide',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'When',
    type: 'text',
    default: null,
    options: null,
  },
  {
    question: 'Do you have any thoughts now',
    type: 'radio',
    options: ['Yes', 'No'],
    default: null,
  },
  {
    question: 'Present Situation',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'Please state why you decided to come for counseling/therapy',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question: 'What is the nature of your situation',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question:
      'What would you like to experience that is different from what you are experiencing now',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question: 'How long has this been a problem for you',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question: 'Please state what you would like to work on in therapy',
    type: 'textarea',
    default: null,
    options: null,
    fullWidth: true,
  },
  {
    question: 'Personal Agreements',
    type: 'heading',
    default: null,
    options: null,
  },
  {
    question: 'null',
    type: 'agreement',
    default: null,
    options: null,
  },
  {
    question: 'I have read and agree to the above terms',
    type: 'checkbox',
    default: null,
    options: ['Yes, I agree'],
    required: true,
    fullWidth: true,
  },
  {
    question: 'Signature',
    type: 'text',
    default: null,
    options: null,
    required: true,
  },
  {
    question: 'Date',
    type: 'date',
    default: null,
    options: null,
    required: true,
  },
];
