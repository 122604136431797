import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import BlockContent from '@components/BlockContent';

import { Container, Image } from '@elements';

import { hasImage } from '@utils/helpers';

const ContentWithImage = ({ data, rawData }) => (
  <SplitContentImage className="">
    {hasImage(data.image) ? (
      <div className="relative lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-16 lg:items-center">
        {rawData.text && (
          <div
            className={`relative grid-text ${
              data.orientation &&
              data.orientation === 'left' &&
              'lg:col-start-2'
            }`}
          >
            <BlockContent blocks={rawData.text || []} />
          </div>
        )}
        {/* <Img fluid={data.image.asset.fluid} alt={data.image.alt || data.image.caption} /> */}
        <Image
          image={data.image}
          className={`mt-10 -mx-4 relative lg:mt-0 grid-image ${
            data.orientation && data.orientation === 'left' && 'lg:col-start-1'
          }`}
        />
      </div>
    ) : (
      <>{rawData.text && <BlockContent blocks={rawData.text || []} />}</>
    )}
  </SplitContentImage>
);

export default ContentWithImage;

const SplitContentImage = styled(Container)`
  /* display: grid;
  grid-template-columns: 1fr 1fr; */

  .image-on-left {
    /* .grid-text {
      grid-column: 2 / 3;
      grid-row: -1;
    }

    .grid-image {
      grid-column: 1 / 2;
      grid-row: -1;
    } */
  }
`;

export const ContentWithImageFragment = graphql`
  fragment ContentWithImageFragment on SanityContentWithImage {
    _key
    _type
    #...GlobalBlockSettingsFragment
    orientation
    image {
      asset {
        fluid(maxWidth: 900) {
          ...GatsbySanityImageFluid
        }
      }
      alt
      roundedCorners
      shadow
      caption
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
