import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Container } from '@elements';
import BlockContent from '@components/BlockContent';
import GoogleMap from '@components/GoogleMap';

const LocationsMap = ({ data, rawData }) => {
  const locationsQuery = useStaticQuery(graphql`
    query LocationsQuery {
      allSanityLocation(
        sort: { fields: group____id, order: DESC }
        filter: { isActive: { ne: false } }
      ) {
        edges {
          node {
            geopoint {
              _type
              alt
              lat
              lng
              _key
            }
            id
            address
            city
            hours
            state
            zip
            group {
              pageInfo {
                name
                slug {
                  current
                }
              }
            }
            phone
            pageInfo {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const locations = locationsQuery.allSanityLocation.edges;

  return (
    <Container>
      <div id="map">
        <span className="sr-only">Map Section</span>
      </div>

      {rawData && rawData.text && (
        <div className="pb-10">
          <BlockContent blocks={rawData.text} />
        </div>
      )}

      <GoogleMap
        locationsData={locations}
        zoom={data.zoom}
        center={data.center}
      />
    </Container>
  );
};

export default LocationsMap;

export const LocationsMapFragment = graphql`
  fragment LocationsMapFragment on SanityLocationsMap {
    _key
    _type
    center {
      _type
      alt
      lat
      lng
      _key
    }
    zoom
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
