import React from 'react';
import { graphql } from 'gatsby';

import BlockContent from '@components/BlockContent';

import { Container } from '@elements';

const Content = ({ rawData }) => (
  <Container className="">
    <BlockContent blocks={rawData.text || []} />
  </Container>
);

export default Content;

export const ContentFragment = graphql`
  fragment ContentFragment on SanityContent {
    _key
    _type
    #...GlobalBlockSettingsFragment
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
