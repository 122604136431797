import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import BlockContent from '@components/BlockContent';

import { hasImage } from '@utils/helpers';

const ContactForm = ({ data, rawData }) => {
  const imageExists = hasImage(data.image);
  if (data.name) {
    return (
      <div className="relative">
        {imageExists && (
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <Img
                fluid={data.image.asset.fluid}
                className="h-64 w-full object-cover lg:absolute lg:h-full"
              />
            </div>
          </div>
        )}
        <div
          className={`relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-${
            imageExists ? '2' : '1'
          }`}
        >
          <div className={`${imageExists && 'lg:pr-8'}`}>
            <div
              className={`max-w-md mx-auto sm:max-w-lg ${
                imageExists && 'lg:mx-0'
              }`}
            >
              <BlockContent blocks={rawData.text} />
              <form
                name={data.name}
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action={
                  data?.submitRedirect?.slug?.pageInfo?.slug?.current
                    ? `/${data.submitRedirect.slug.pageInfo.slug.current}`
                    : '/'
                }
                className="mt-9 grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value={data.name} />
                <label htmlFor="name" className="sm:col-span-2">
                  <span className="block font-medium leading-5 ">
                    Full name
                  </span>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </label>
                <label htmlFor="email" className="sm:col-span-2">
                  <span
                    htmlFor="email"
                    className="block text-sm font-medium leading-5 "
                  >
                    Email
                  </span>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </label>
                <label htmlFor="phone" className="sm:col-span-2">
                  <div className="flex justify-between">
                    <span className="block text-sm font-medium leading-5 ">
                      Phone
                    </span>
                    <span className="text-sm leading-5 opacity-75">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </label>
                <label htmlFor="message" className="sm:col-span-2">
                  <div className="flex justify-between">
                    <span className="block text-sm font-medium leading-5 ">
                      How may we assist you?
                    </span>
                    {/* <span className="text-sm leading-5 ">Max. 500 characters</span> */}
                  </div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      className="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    />
                  </div>
                </label>
                <div className="text-right sm:col-span-2">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      type="submit"
                      name="submit"
                      className="btn btn-primary"
                    >
                      {data.submitButton ? data.submitButton : 'Submit'}
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default ContactForm;

export const ContactFormFragment = graphql`
  fragment ContactFormFragment on SanityContactForm {
    _key
    _type
    name
    submitButton
    email
    image {
      asset {
        fluid(maxWidth: 1600) {
          ...GatsbySanityImageFluid
        }
      }
      alt
      caption
    }
    submitRedirect {
      ...InternalLinkFragment
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
