import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import { MapMarker } from '@elements';
import {
  // createMapOptions,
  createMapOptionsSmall,
} from '@components/GoogleMap/map.config';

const GoogleMap = ({ locationsData, zoom, center }) => {
  const mapCenter = {
    lat: 27.928563,
    lng: -82.338945,
  };

  return (
    <div>
      {locationsData && locationsData !== 0 && (
        <div className="rounded-lg overflow-hidden">
          <GoogleMapWrap>
            <div>
              <GoogleMapEl
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_MAP_API_KEY || 'xxx',
                }}
                defaultCenter={
                  center || locationsData[0].node.geopoint || mapCenter
                }
                defaultZoom={zoom || 12}
                // onChildMouseEnter={onChildMouseEnter}
                // onChildMouseLeave={onChildMouseLeave}
                // yesIWantToUseGoogleMapApiInternals
                // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                options={createMapOptionsSmall}
              >
                {locationsData.map(function(edge, i) {
                  const location = edge.node;

                  if (location.geopoint) {
                    const { lat, lng } = location.geopoint;
                    if (lat && lng) {
                      return (
                        <Marker key={location.id} lat={lat} lng={lng}>
                          <MapMarker location={location} />
                        </Marker>
                      );
                    }
                  }
                  return null;
                })}
              </GoogleMapEl>
            </div>
          </GoogleMapWrap>
        </div>
      )}
    </div>
  );
};

const GoogleMapWrap = styled.div`
  height: 0;
  padding-top: calc((440 / 575) * 100%);
  position: relative;

  > div {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  > div > div > div {
    border-radius: 0.5rem; /* Hacky way to get the map to have rounded corners */
  }
`;

const GoogleMapEl = styled(GoogleMapReact)`
  height: 100%;
  width: 100%;
  z-index: 0;
`;

const Marker = styled.div``;

export default GoogleMap;
