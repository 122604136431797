import React from 'react';
// import tw from 'tailwind.macro';
// import { Link } from 'gatsby';

import Layout from '@components/Layout';
import Seo from '@components/Seo';
import BlocksRenderer from '@components/BlocksRenderer';

const PageDefault = ({ pageProps, pageData }) => {
  const seo = pageData && pageData.seo ? pageData.seo : '';

  return (
    <>
      <Seo context={pageProps.pageContext} {...seo} />
      <Layout>
        <BlocksRenderer pageData={pageData} pageProps={pageProps} />
      </Layout>
    </>
  );
};
export default PageDefault;
