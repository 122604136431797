import React from 'react';
import { graphql } from 'gatsby';
// import axios from 'axios';
import Recaptcha from 'react-recaptcha';

import BlockContent from '@components/BlockContent';

import { simpleFormBuilder } from '@utils/helpers';

import { fields } from './ClientIntakeFormFields';

const ClientIntakeForm = ({ data, rawData }) => {
  const [status, setStatus] = React.useState('');
  const [verified, setVerified] = React.useState(false);

  function submitForm(ev) {
    // console.log('verified?:', verified);
    ev.preventDefault();
    const form = ev.target;
    const formData = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
      }
    };

    if (verified) {
      xhr.send(formData);
    }
  }

  const callback = function () {
    // console.log('Recaptcha Callback');
  };

  // specifying verify callback function
  const verifyCallback = function (response) {
    // console.log('Recaptcha Verified');
    // console.log(response);
    setVerified(true);
  };

  const expiredCallback = function (response) {
    // console.log('Recaptcha expired');
    setVerified(false);
  };

  return (
    <div className="relative">
      <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-1">
        <div className="">
          <div className={`max-w-md mx-auto sm:max-w-7xl `}>
            <BlockContent blocks={rawData.text} />
            <form
              onSubmit={submitForm}
              action={data.formspreeEndpoint}
              method="POST"
            >
              <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                {fields?.length > 0 &&
                  fields.map((field, i) => simpleFormBuilder(field, i))}
              </div>

              <div className="mt-6 flex-col">
                <div className="mb-2">
                  To submit the form, you must first verify you aren't a robot
                  🤖.
                </div>
                <Recaptcha
                  sitekey="6Le2h2MaAAAAAAZLhhSnqneHc8wx7zggJnLjPXYE"
                  render="explicit"
                  verifyCallback={verifyCallback}
                  expiredCallback={expiredCallback}
                  onloadCallback={callback}
                />
              </div>

              <div className="text-right sm:col-span-2">
                <span className="inline-flex rounded-md shadow-sm">
                  {status === 'SUCCESS' ? (
                    <p>Submission Successful!</p>
                  ) : (
                    <button
                      type="submit"
                      name="submit"
                      className="btn btn-primary mt-8 disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={!verified}
                    >
                      {data.submitButton ? data.submitButton : 'Submit'}
                    </button>
                  )}
                  {status === 'ERROR' && <p>Ooops! There was an error.</p>}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientIntakeForm;

export const ClientIntakeFormFragment = graphql`
  fragment ClientIntakeFormFragment on SanityClientIntakeForm {
    _key
    _type
    submitButton
    formspreeEndpoint
    submitRedirect {
      ...InternalLinkFragment
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
