import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
// import Img from 'gatsby-image';

import { hasImage } from '@utils/helpers';
import { Container, ButtonGroup } from '@elements';
import BlockContentSimple from '@components/BlockContentSimple';

const BannerSimple = ({ data, rawData }) => {
  const imageExists = hasImage(data.image);

  return (
    <BannerSimpleWrap
      className={`${
        data.backgroundColor &&
        (data.backgroundColor === 'primary' ||
          data.backgroundColor === 'secondary')
          ? `bg-${data.backgroundColor} color-mode-dark`
          : ''
      } ${
        !data.backgroundColor || data.backgroundColor === 'inherit'
          ? 'color-mode-toggle'
          : ''
      } ${imageExists ? 'color-mode-dark' : ''} relative overflow-hidden`}
    >
      {imageExists && (
        <>
          <Img
            className="absolute top-0 left-0 object-cover object-center w-full h-full"
            fluid={data.image.asset.fluid}
            alt={data.image.alt}
          />
          <div
            className={`absolute top-0 left-0 w-full h-full bg-black opacity-${
              Number.isInteger(data.coverOpacity) ? data.coverOpacity : '50'
            } pointer-events-none`}
          />
        </>
      )}
      <Container className="z-10 relative max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="">
          <BlockContentSimple
            blocks={rawData.textLarge || []}
            className={`${imageExists &&
              'text-white'} text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10`}
          />
        </h2>

        <BlockContentSimple
          blocks={rawData.textSmall || []}
          className={`${imageExists && 'text-white'}`}
        />

        <ButtonGroup
          buttons={data.buttons}
          backgroundColor={data.backgroundColor}
          hasImageAsBackground={imageExists}
          className=""
        />
      </Container>
    </BannerSimpleWrap>
  );
};

export default BannerSimple;

const BannerSimpleWrap = styled.section``;

export const BannerSimpleFragment = graphql`
  fragment BannerSimpleFragment on SanityBannerSimple {
    _key
    _type
    backgroundColor
    buttons {
      ...ButtonFragment
    }
    coverOpacity
    image {
      asset {
        fluid(maxWidth: 1600) {
          ...GatsbySanityImageFluid
        }
      }
      alt
      caption
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;

// <>
//   <section className="color-mode-toggle">
//     <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
//       <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
//         Submit an application.
//       </h2>
//       <div className="mt-8 flex justify-center">
//         <div className="inline-flex rounded-md shadow">
//           <a href="/" className="btn">
//             Crew Member
//           </a>
//         </div>
//         <div className="ml-3 inline-flex">
//           <a href="/" className="btn btn-ghost">
//             Restaurant Manager
//           </a>
//         </div>
//       </div>
//     </div>
//   </section>
//   <section className="bg-secondary color-mode-dark">
//     <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
//       <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
//         Submit an application.
//       </h2>
//       <div className="mt-8 flex justify-center">
//         {data.buttons &&
//             data.buttons.map(button => (
//               <Button
//                 key={button._key}
//                 buttonObject={button}
//                 className={`w-3/4 mb-3 sm:mr-3 sm:mb-0 shadow-none sm:w-auto ${
//                   button.isGhost
//                     ? 'btn-ghost border-white text-white hover:border-primary hover:bg-primary'
//                     : ''
//                 }`}
//               />
//             ))}
//         </div>
//       </div>
//     </div>
//   </section>
//   <section className="bg-primary color-mode-dark">
//     <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
//       <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
//         Submit an application.
//       </h2>
//       <div className="mt-8 flex justify-center">
//         <div className="inline-flex rounded-md shadow">
//           <a href="/" className="btn hover:bg-gray-900 hover:border-gray-900">
//             Crew Member
//           </a>
//         </div>
//         <div className="ml-3 inline-flex">
//           <a
//             href="/"
//             className="btn btn-ghost hover:border-white hover:bg-white hover:text-gray-900"
//           >
//             Restaurant Manager
//           </a>
//         </div>
//       </div>
//     </div>
//   </section>
// </>
