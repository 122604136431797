import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = (props) => {
  const {
    title,
    description,
    keywords,
    image,
    author,
    type,
    url,
    index,
    context,
    follow,
  } = props;

  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      settings: sanitySettings {
        siteName
        title
        titleBase
        description
        keywords
        lang
        baseUrl
        twitterHandle
        facebookAppId
        image {
          asset {
            url
          }
        }
      }
    }
  `);

  // URL configuration
  const { baseUrl } = data.settings || ''; // Get base url
  let tempUrl;

  if (url) {
    tempUrl = url;
  } else if (
    context &&
    context.slug &&
    context.slug !== '' &&
    context.slug !== '/' &&
    baseUrl
  ) {
    tempUrl = `${baseUrl}/${context.slug}`;
  } else {
    tempUrl = baseUrl || null;
  }

  // Index/Follow configuration
  let tempIndex = '';
  if (index === false) {
    tempIndex = 'noindex';
  } else {
    tempIndex = 'index';
  }

  // Index/Follow configuration
  let tempFollow = '';
  if (follow === false) {
    tempFollow = 'nofollow';
  } else {
    tempFollow = 'follow';
  }

  const metaSiteName = description || data.settings.siteName || '';
  const metaDescription = description || data.settings.description || '';
  const metaTitle = title || data.settings.title || '';
  const titleTemplate = data.titleBase || '';
  const metaAuthor = author || '';
  const metaKeywords =
    (keywords && keywords.length > 0 ? keywords.join(', ') : false) ||
    (data.settings.keywords && data.settings.keywords.length > 0
      ? data.settings.keywords.join(', ')
      : false) ||
    '';
  const metaUrl = tempUrl;
  const metaLang = data.settings.lang || 'en-US';
  const metaIndex = tempIndex;
  const metaFollow = tempFollow;
  const metaImage =
    (image && image.asset && image.asset.url
      ? `${image.asset.url}?w=1200&h=630&fit=crop`
      : false) ||
    (data &&
    data.settings &&
    data.settings.image &&
    data.settings.image.asset &&
    data.settings.image.asset.url
      ? `${data.settings.image.asset.url}?w=1200&h=630&fit=crop`
      : false) ||
    '';
  const metaType = type || 'website';
  const metatwitterHandle = data.settings.twitterHandle || '';
  const metafacebookAppId = data.settings.facebookAppId || '';

  return (
    <Helmet title={metaTitle} titleTemplate={titleTemplate}>
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaImage && <meta name="image" content={metaImage} />}
      {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      <meta name="robots" content={`${metaIndex}, ${metaFollow}`} />

      {metaSiteName && <meta property="og:site_name" content={metaSiteName} />}
      {metaUrl && <meta property="og:url" content={metaUrl} />}
      {metaType && <meta property="og:type" content={metaType} />}
      {metaLang && <meta property="og:locale" content={metaLang} />}
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {metaDescription && (
        <meta property="og:description" content={metaDescription} />
      )}
      {metaImage && <meta property="og:image" content={metaImage} />}
      {metaTitle && <meta property="og:image_alt" content={metaTitle} />}
      {metaAuthor && <meta property="og:author" content={metaAuthor} />}

      <meta name="twitter:card" content="summary_large_image" />
      {metatwitterHandle && (
        <meta name="twitter:creator" content={metatwitterHandle} />
      )}
      {metaTitle && <meta name="twitter:title" content={metaTitle} />}
      {metaDescription && (
        <meta name="twitter:description" content={metaDescription} />
      )}
      {metaImage && <meta name="twitter:image" content={metaImage} />}

      {metafacebookAppId && (
        <meta property="fb:app_id" content={metafacebookAppId} />
      )}
    </Helmet>
  );
};

export default Seo;

export const query = graphql`
  fragment SeoFragment on SanitySeo {
    title
    url
    type
    keywords
    index
    follow
    image {
      asset {
        url
      }
    }
    description
  }
`;
